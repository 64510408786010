<template>
	<v-app id="inspire">
		<v-system-bar app>
			<v-spacer></v-spacer>

			<v-icon>mdi-square</v-icon>

			<v-icon>mdi-circle</v-icon>

			<v-icon>mdi-triangle</v-icon>
		</v-system-bar>

		<v-navigation-drawer v-model="drawer" app width="300" expand-on-hover class="navigationRightDrawer">
			<v-list dense nav>
				<v-list-item v-for="item in leftMenuItems" :key="item.route" link>
					<v-list-item-icon>
						<v-icon>
							{{ item.icon }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							<router-link :to="item.route">{{ item.value }}</router-link>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<div style="position: absolute; right: -16px; top: 20px; padding: 10px 4px; z-index: 1;">
				<v-btn
					dark
					fab
					x-small
					@click.stop.prevent="toggleLeftMenu"
					color="#9e9e9e"
					class="navigationDrawerToggleButton"
					:class="navigationDrawerIsOpened ? '' : 'navigationDrawerToggleButtonClosed'"
				>
					<v-icon>keyboard_arrow_left</v-icon>
				</v-btn>
			</div>
		</v-navigation-drawer>

		<v-content>
			<router-view :headerHeight="headerHeight" />
		</v-content>

		<v-footer app color="transparent" height="72" inset>
			&copy; Copyright
		</v-footer>
	</v-app>
</template>

<script>
export default {
	data: () => ({
		drawer: null,
		leftMenuItems: [
			{ route: '/edit-products', value: 'Termékek', icon: 'mdi-view-dashboard' },
			{ route: '/orders', value: 'Megrendelések', icon: 'mdi-currency-usd' },
			{ route: '/messages', value: 'Üzenetek', icon: 'mdi-email' },
			{ route: '/settings', value: 'Beállítások', icon: 'mdi-cog' }
		],
		products: [{ name: '' }],
		dialog: false
	})
};
</script>

<style>
.navigationRightDrawer {
	font-size: 10pt;
	border-right: 1px solid #d8d8d8 !important;
	color: #616161 !important;
	overflow: unset !important;
}
.navigationDrawerToggleButtonClosed .v-icon {
	transform: rotate(180deg);
}
.v-btn--fab.v-size--x-small {
	height: 22px !important;
	width: 22px !important;
}
</style>
